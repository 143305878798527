
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    modalController,
    IonButtons,
    menuController,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiInterventi from "@/services/interventi";

import InterventoDetail from "@/custom/InterventoDetail.vue";
import ModalNuovoIntervento from "@/components/ModalNuovoIntervento.vue";

export default {
    name: "Interventi",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const interventi = ref([]);
        async function loadInterventi() {
            loading.value = true;
            try {
                interventi.value = await apiInterventi.getAllInterventi(userID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli interventi", "toast_danger");
            }
            loading.value = false;
        }

        /**
         * ! Set correct customer data
         */
        const riferimentoCliente = (intervento) => {
            if (intervento.customers_company) {
                return intervento.customers_company;
            } else if (intervento.customers_name && !intervento.customers_last_name) {
                return `${intervento.customers_name}`;
            }
            return `${intervento.customers_name} ${intervento.customers_last_name}`;
        };

        /**
         * ! Set correct background for intervento status
         */
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * ! Open modal to create new intervento
         */
        async function openNuovoIntervento() {
            const modal = await modalController.create({
                component: ModalNuovoIntervento,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    interventi.value.unshift(detail.data);
                    openToast("Intervento salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Open intervento detail
         */
        async function openInterventoDetail(intervento) {
            const modal = await modalController.create({
                component: InterventoDetail,
                componentProps: {
                    data: intervento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                console.log(detail);
                //Se ho modificat un intervento (firmato) devo richiedere i dati
                if (detail.data && detail.data.intervento_modificato === true) {
                    loadInterventi();
                }
                /* if (detail.data != undefined) {
                    interventi.value.unshift(detail.data);
                    openToast("Intervento salvato con successo", "toast_success");
                } */
            });
            return modal.present();
        }

        onIonViewWillEnter(() => {
            loadInterventi();
        });

        onMounted(() => {
            //GET INTERVENTI DATA
            //loadInterventi();
        });

        return {
            loading,
            add,
            refresh,
            openNuovoIntervento,
            //getRichieste,
            openInterventoDetail,
            openMenu,
            menu,
            statusIntervento,
            //Dati interventi
            loadInterventi,
            interventi,
            dateFormat,
            riferimentoCliente,
        };
    },
};
